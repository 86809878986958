import React from "react";
import { useParams } from "react-router";
import { ApiGet, ApiPost, ApiPut } from "src/util/ApiClient";
import { Button, Card, Col, Divider, Form, Input, Row, Select } from "antd";
import { setCurrentRoutes } from "src/appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { formatRupiah } from "src/util/Formater";
import { JsonDataViewer } from "./JsonDataView";
import { SendOutlined } from "@ant-design/icons";
import { useDiscount } from "src/hooks/discount";
import { useApiErrorAlert } from "src/hooks/user";
import { defaultCustomerValue, defaultOrderValue, ICustomerModel, IOrderModel } from "src/type-interface/model";
import { ServiceGroupName } from "src/hooks/order";
import TextArea from "antd/lib/input/TextArea";
import { StatusOrdeSelect } from "src/components/Shared/SelectComponents";

const OrderDetailPage: React.FC = () => {

  const { id } = useParams();
  const [isInviteLoading, setIsInviteLoading] = React.useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = React.useState<boolean>(false);

  const [order, setOrder] = React.useState<IOrderModel>(defaultOrderValue);
  const [customer, setCustomer] = React.useState<ICustomerModel>(defaultCustomerValue);

  const { authUser } = useSelector(({ auth }: { auth: any }) => auth);

  const apiErrorAlert = useApiErrorAlert();
  const { listDiscount, loadDiscount, getSelectDiscount, calculateDiscount } = useDiscount();
  const dispatch = useDispatch();

  const loadCustomer = React.useCallback(async (id: number) => {
    const res = await ApiGet(`/customer/detail/${id}`);
    if (res.data) {
      setCustomer(res.data.data);
      loadDiscount(res.data.data.partner_code);
    } else if (!res.success) {
      apiErrorAlert(res);
    }
  }, [customer, listDiscount]);

  const idOrderKhOriginal = React.useRef("");
  const idStatusOrderOriginal = React.useRef("");

  const loadOrderDetail = React.useCallback(async () => {
    setIsSaveLoading(true);
    const res = await ApiGet(`/order/detail/${id}`);
    if (res.success) {
      setOrder(res.data.data);
      loadCustomer(res.data.data.customer_id);

      idOrderKhOriginal.current = res.data.data.id_order_kh;
      idStatusOrderOriginal.current = res.data.data.order_status;

      dispatch(setCurrentRoutes([
        {
          breadcrumbName: "Transaksi",
        },
        {
          path: "/order",
          breadcrumbName: "Daftar Pesanan",
        },
        {
          path: "",
          breadcrumbName: "Pesanan Detail"
        }
      ]));
    } else {
      apiErrorAlert(res);
    }
    setIsSaveLoading(false);
  }, [order, id, idOrderKhOriginal, idStatusOrderOriginal, isSaveLoading]);

  React.useEffect(() => {
    loadOrderDetail();
  }, []);

  const onChangeOrder = React.useCallback((key: string, value: string | number) => {
    let update = { [key]: value };
    if (key === "discount_id") {
      update = { ...update, ...calculateDiscount(order.sub_total, Number(value)) };
    }
    setOrder({ ...order, ...update });
  }, [order, listDiscount]);


  const onSaveData = React.useCallback(async () => {
    setIsSaveLoading(true);
    const res = await ApiPut(`/order/update/${order.order_id}`, { customer, order });
    apiErrorAlert(res, loadOrderDetail);
  }, [customer, order, isSaveLoading]);

  const onInviteKhAccount = React.useCallback(async () => {
    setIsInviteLoading(true);
    const res = await ApiPost(`/sendInviteKhAccount/${order.order_id}`, {});
    setIsInviteLoading(false);
    apiErrorAlert(res);
  }, [order, isInviteLoading]);

  const onMarkAsPaid = React.useCallback(async () => {
    setIsSaveLoading(true);
    const res = await ApiPost(`/order/mark-as-paid/${order.order_id}`, {});
    if (res.success) {
      await loadOrderDetail();
    }
  }, [customer, order, isSaveLoading]);

  const readyTopaid = React.useCallback(() => {

    let readyPaid = false;
    if (order.order_status === "paid" || !order.json_data) {
      return readyPaid;
    }

    if (order.service_group_code === "pendirian-badan" && order.json_data.namaBadanUsaha && order.json_data.namaBadanUsaha.namaBadanUsaha1 !== "") {
      readyPaid = true;
    } else if (order.service_group_code === "pendaftaran-merek" && order.json_data.dataMerek?.namaMerek && order.json_data.dataMerek?.namaMerek !== "") {
      readyPaid = true;
    }

    return readyPaid && idStatusOrderOriginal.current !== "paid";

  }, [order, idStatusOrderOriginal]);

  return (
    <div className="gx-main-content">
      <Row>
        <Col className="ant-col-24">
          <Card bordered={true} title={
            <Row>
              <Col className="ant-col-12">Data Order - <ServiceGroupName service_group_code={order.service_group_code} /> </Col>
              {
                authUser?.type !== "readonly" &&
                <Col className="ant-col-12 text-right">
                  <Button type="primary" loading={isSaveLoading} onClick={onSaveData}>Simpan</Button>
                  {readyTopaid() && <Button type="dashed" onClick={onMarkAsPaid}>Mark As Paid</Button>}
                </Col>
              }
            </Row>
          }
            style={{ marginBottom: 0 }}>
            <Divider orientation="left">Informasi Umum</Divider>
            <Row>
              <Col className="ant-col ant-col-12 ant-col-xs-24 ant-col-xl-12">
                <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                  {
                    customer &&
                    <>
                      <Form.Item label="Pelanggan" htmlFor="full_name" rules={[{ required: true, message: "Mohon input nama pelanggan!" }]}>
                        <Input id="full_name" value={customer.full_name} disabled={true} onChange={(e) => { }} />
                      </Form.Item>
                      <Form.Item label="No Telp" htmlFor="phone">
                        <Input.Group>
                          <span className="ant-input-group-addon">+62</span>
                          <Input id="phone" value={customer.phone} disabled={true} />
                        </Input.Group>
                      </Form.Item>
                      <Form.Item label="Email" htmlFor="email">
                        <Input id="email" value={customer.email} disabled={true} />
                      </Form.Item>
                    </>
                  }
                  <Form.Item label="Nomor Pesanan">
                    <Input value={order.invoice_number} disabled={true} />
                  </Form.Item>
                  <Form.Item label="Layanan" htmlFor="description">
                    <TextArea id="description" value={order.description} disabled={true} rows={2} />
                  </Form.Item>
                  <Form.Item label="Tanggal Pemesanan">
                    <Input value={order.order_date} disabled={true} />
                  </Form.Item>
                </Form>
              </Col>
              <Col className="ant-col ant-col-12 ant-col-xs-24 ant-col-xl-12">
                <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                  <Form.Item label="Sub Total">
                    <Input value={formatRupiah(order.sub_total)} disabled={true} />
                  </Form.Item>
                  <Form.Item label="Diskon" htmlFor="discount">
                    <Select
                      id="discount_id"
                      className="gx-mb-2"
                      defaultValue=""
                      value={String(order.discount_id || "")}
                      size="middle"
                      onChange={(val) => onChangeOrder("discount_id", val)}
                      options={getSelectDiscount()}
                      dropdownMatchSelectWidth={true}
                      disabled={order.order_status === "paid" || authUser.type === "readonly"} />
                  </Form.Item>
                  {
                    order.discount > 0 &&
                  <Form.Item label="Grand Total">
                    <Input value={formatRupiah(order.total)} disabled={true} />
                  </Form.Item>
                  }
                  <Form.Item label="No Referensi" htmlFor="reference_number">
                    <Input
                      id="reference_number"
                      value={order.reference_number}
                      disabled={authUser.type === "readonly"}
                      onChange={(e) => onChangeOrder("reference_number", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Status Order" htmlFor="order_status">
                    <StatusOrdeSelect
                      id="order_status"
                      className="gx-mb-2"
                      defaultValue=""
                      value={order.order_status}
                      disabled={authUser.type !== "root" || order.order_status === "paid"}
                      onChange={(val) => onChangeOrder("order_status", val)} />
                  </Form.Item>
                  {
                    authUser.type === "root" &&
                    <Form.Item label="ID Order KH" htmlFor="id_order_kh">
                      {/* <Row>
                        <Col className="ant-col-24"> */}
                      <Input id="id_order_kh" disabled={order.order_status !== "paid"} value={order.id_order_kh} onChange={(e) => onChangeOrder("id_order_kh", e.target.value)} />
                      {/*   </Col>
                      </Row>
                      {
                        idOrderKhOriginal.current &&
                        <Row style={{ marginTop: 16 }} className="text-right">
                          <Col className="ant-col-24">
                            <Button type="primary" loading={isInviteLoading} onClick={onInviteKhAccount}>
                              {!isInviteLoading && <SendOutlined />}Undang Akun KH
                            </Button>
                          </Col>
                        </Row>
                      } */}
                    </Form.Item>
                  }
                </Form>
              </Col>
            </Row>
            {
              order.json_data && Object.keys(order.json_data).length > 0 && <Divider orientation="left">Informasi Detail</Divider>
            }
            <Row>
              <Col className="ant-col-24">
                <JsonDataViewer
                  key="JsonDataViewer"
                  order={order}
                  data={order.json_data || {}}
                  onChangeValue={(grupData: string, key: string, value: any, index?: number) => {
                    console.log("grupData", grupData);
                    console.log("key", key);
                    console.log("value", value);
                    console.log("index", index);
                  }} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OrderDetailPage;
